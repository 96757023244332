export default {
    "ACCOUNT": 1,
    "BILLING": 5,
    "DESKTOP_APP": 16,
    "LICENSE": 6,
    "MACHINE": 13,
    "MACHINE_10": 7,
    "MACHINE_2": 10,
    "MACHINE_6": 9,
    "MACHINE_3": 8,
    "MACHINE_8": 8,
    "PAYMENT": 11,
    "TRANSACTION": 4,
    "TRANSFER_DATA": 14
}