import commonAct from '@/commonActionHandle.js';
import baseComponent from "@/scripts/baseComponent";
import lazyLoadComponent from '@/scripts/lazyLoadComponent';
import SkeletonBox from '@/components/SkeletonBoxWithoutLoading';
import gpuHubBillingApi from '@/api/gpuHub/gpuHubBilling';
import vmMachineApi from '@/api/gpuHub/vmMachines';
import { debounce } from 'vue-debounce';
import { getlazyLoadInfo } from "@/scripts/_helpers/userHelper";
import moment from "moment";
import { mapState } from "vuex";
import { colorGpuNodeColor } from "@/constant/vmMachineStatusConst";
import mappingTicketType from "@/constant/mappingTicketType"; 
export default {
    extends: baseComponent,
    props: {
        cardHeight: {
            type: String,
            default: 'height: calc(100dvh - 7.2rem);'
        },
        minInfo: {
            type: Boolean,
            default: false
        },
        ownedTarget: {
            type: String,
            default: null
        },
        machineTarget: {
            type: Number,
            default: null
        },
    },
    components: {
        paidTimes: lazyLoadComponent({
            componentFactory: () => import("@/views/components/common/PaidTimes"),
            loading: SkeletonBox,
        }),
        reportSuportTicketModalContent: lazyLoadComponent({
            componentFactory: () => import("@/views/modal/RentalService/ReportSupportTicket"),
            loading: SkeletonBox,
        }),
    },
    watch: {
        machineTarget(newVal, oldVal) {
            if (this.ownedTarget !== null) this.getMachineByUser();
            this.refreshClick();
        },
        ownedTarget(newVal, oldVal) {
            this.refreshClick();
            this.getMachineByUser();
        }
    },
    data() {
        return {
            debounceFnc: null,
            debounceFilterFnc: null,
            colorGpuNodeColor: colorGpuNodeColor,
            elements: {
                queryAction: "",
                querySearch: "",
                filterItem: {},
                data: [],
                pagingItem: {
                    pageIndex: 1,
                    pageSize: 20,
                    numberOfPage: 1,
                    outRowsNumber: 0,
                    orderBy: "created_at",
                    directionSort: "desc"
                }
            },
            rentalPackage: [],
            machineByUser: [],
            toogleFilter: false,
            elementDetail: null,
            createBillingProcessing: false,
            createBillingModal: {
                machineId: "",
                packageId: ""
            },
            createBillingModalTimeSelected: [],
            fixedRentalPeriod: {
                "1": "week",
                "2": "month",
                "3": "day",
            },
            reportTicketModal: {
                isShowModal: false,
                machineInfo: null,
                postModelData: null
            }
        };
    },
    filters: {
    },
    computed: {
        ...mapState({
            userInfo: state => state.auth.user,
            rolesInfo: state => state.auth.roles,
            listServicePack: (state) => state.common.listServicePack.reduce(function(acc, cur, i) {
                                      acc[cur.id] = cur;
                                      return acc;
                                    }, {}),
        }),
        defaultPagingItem() {
            return {
                pageIndex: 1,
                pageSize: 20,
                numberOfPage: 1,
                outRowsNumber: 0,
                orderBy: "created_at",
                directionSort: "desc"
            };
        },
        requestParam() {
            return {
                ownedTarget: this.ownedTarget,
                queryAction: this.elements.queryAction,
                querySearch: this.elements.querySearch,
                pageSize: this.elements.pagingItem.pageSize,
                machineId: this.machineTarget,
                filterItem: this.elements.filterItem
            };
        },
        machinePackageFilter() {
            return this.rentalPackage.map(x => {
                return { id: x.id, text: x.name };
            });
        },
        machineListOfUserFilter() {
            return this.machineByUser.map(x => {
                return { id: x.vmMachineId, text: `${x.vmMachineId} - ${x.vmMachineInfo.name} - ${x.vmMachineInfo.rentalServicePack.name}` };
            });
        },
        upTimeCreateBilling() {
            if (this.createBillingModalTimeSelected.length == 2) {
                var seconds = moment
                    .duration(moment(this.createBillingModalTimeSelected[1])
                        .diff(moment(this.createBillingModalTimeSelected[0]))
                    ).asSeconds();
                return seconds;
            }
            else return 0;
        },
        allowCreateBilling() {
            return (((this.createBillingModal.machineId !== undefined && this.createBillingModal.machineId !== '')
                || (this.createBillingModal.packageId !== undefined && this.createBillingModal.packageId !== ''))
                && this.upTimeCreateBilling > 0);
        },
        elementParse() {
            return this.elements.data.map(obj => {
                var vmInfoParse = obj.vmMachineInfo;
                if (obj.machineId === null || obj.machineId === '') {
                    if (vmInfoParse.id !== 0) {
                        vmInfoParse = JSON.parse(obj.machineInfo);
                        var keysUpper = Object.keys(vmInfoParse);
                        var newObj = {}
                        for (var i in keysUpper) {
                            newObj[keysUpper[i][0].toLowerCase() + keysUpper[i].substring(1)] = vmInfoParse[keysUpper[i]];
                            if (keysUpper[i][0].toLowerCase() + keysUpper[i].substring(1) === 'rentalServicePack') {
                                var keysUpper1 = Object.keys(newObj.rentalServicePack);
                                var newRentalServicePack = {};
                                for (var j in keysUpper1) {
                                    newRentalServicePack[keysUpper1[j][0].toLowerCase() + keysUpper1[j].substring(1)] = newObj.rentalServicePack[keysUpper1[j]];
                                }
                                newObj.rentalServicePack = newRentalServicePack;
                            }
                        }
                        newObj.packageName = newObj.rentalServicePack.name;
                        vmInfoParse = newObj;
                    }
                }
                return ({
                    ...obj,
                    vmMachineInfo: vmInfoParse
                });
            });
        },
    },
    created() {
        this.loadRentalPackage();
        if (this.ownedTarget !== null) this.getMachineByUser();
        this.getAllResult(1);
        this.debounceFnc = debounce(() => {
            this.getSearchQueryResult(1);
        }, 1000);
        this.debounceFilterFnc = debounce(() => {
            this.getFilterQueryResult(1);
        }, 1000);
    },
    methods: {
        loadRentalPackage() {
            vmMachineApi.getPackageList().then(response => {
                if (response.data && response.data.result === 0 && response.data.data !== null) {
                    this.rentalPackage = response.data.data.data;
                    this.hideLoading();
                }
                else {
                    this.rentalPackage = [];
                    if (response.data && response.data.message !== null && response.data.message !== '') {
                        console.error(response.data.message || this.$lang.common.error);
                    }
                }
            }).catch(error => {
                console.error(error);
                this.rentalPackage = [];
            });
        },
        getMachineByUser() {
            console.log("getMachineByUser()");
            vmMachineApi.getMachineByUser(this.ownedTarget).then(response => {
                if (response.data && response.data.result === 0 && response.data.data !== null) {
                    this.machineByUser = response.data.data;
                    console.log("this.machineByUser", this.machineByUser);
                    this.hideLoading();
                }
                else {
                    this.machineByUser = [];
                    if (response.data && response.data.message !== null && response.data.message !== '') {
                        console.error(response.data.message || this.$lang.common.error);
                    }
                }
            }).catch(error => {
                console.error(error);
                this.machineByUser = [];
            });
        },
        getElementsList(pageNumber) {
            this.showLoading();
            gpuHubBillingApi.getElementsList(pageNumber, this.requestParam).then(response => {
                if (response.data && response.data.result === 0 && response.data.data !== null) {
                    this.elements.data = response.data.data.data;
                    this.elements.data.forEach(item => {
                        try {
                            if (item.machineInfo !== null) item.machineInfoObject = JSON.parse(item.machineInfo);
                        } catch (error) {
                            console.error(error);
                        }
                    });
                    this.elements.pagingItem = response.data.data.pagingItem;
                    this.hideLoading();
                }
                else {
                    this.elements.data = [];
                    this.elements.pagingItem = this.defaultPagingItem;
                    this.hideLoading();
                    if (response.data && response.data.message !== null && response.data.message !== '') {
                        commonAct.showError(response.data.message || this.$lang.common.error);
                    }
                }
            }).catch(error => {
                console.error(error);
                this.elements.data = [];
                this.elements.pagingItem = this.defaultPagingItem;
                this.hideLoading();
                commonAct.showError(error);

            });
        },
        getAllResult(pageNumber) {
            this.elements.queryAction = "";
            this.elements.filterItem = {};
            this.getElementsList(pageNumber);
        },
        getSearchQueryResult(pageNumber) {
            this.elements.queryAction = "search";
            this.elements.filterItem = {};
            this.getElementsList(pageNumber);
        },
        getFilterQueryResult(pageNumber) {
            this.elements.queryAction = "filter";
            this.getElementsList(pageNumber);
        },
        pageClickHandle(pageNumber) {
            switch (this.elements.queryAction) {
                case "filter":
                    this.getFilterQueryResult(pageNumber);
                    break;
                case "search":
                    this.getSearchQueryResult(pageNumber);
                    break;
                default:
                    this.getAllResult(pageNumber);
                    break;
            }
        },
        refreshClick() {
            this.elements.querySearch = "";
            this.getAllResult(1);
        },
        onSearchInput(isDebounce) {
            if (isDebounce) {
                if (this.debounceFnc) this.debounceFnc();
            } else {
                this.getSearchQueryResult(1);
            }
        },
        onFilterInput(isDebounce) {
            if (isDebounce) {
                if (this.debounceFilterFnc) this.debounceFilterFnc();
            } else {
                this.getFilterQueryResult(1);
            }
        },
        viewDetailInfo(targetItem) {
            this.$set(this, "elementDetail", targetItem);
            this.$nextTick(() => {
                $("#detailModalInfo").modal({ backdrop: 'static', keyboard: true, });
            });
        },
        closeDetailInfo() {
            $("#detailModalInfo").modal("hide");
            this.$set(this, "elementDetail", null);
        },

        viewCreateBillingModal() {
            console.log("ownedTarget", this.ownedTarget);
            if (this.ownedTarget !== null) this.getMachineByUser();
            this.$set(this, "createBillingModal", { machineId: (this.machineTarget !== null ? this.machineTarget : "") });
            this.$set(this, "createBillingModalTimeSelected", [moment(), moment()]);
            this.$nextTick(() => {
                $("#createBillingManualModal").modal({ backdrop: 'static', keyboard: true, });
            });
        },
        closeCreateBilling() {
            $("#createBillingManualModal").modal("hide");
            this.$set(this, "createBillingModal", {
                machineId: "",
                packageId: ""
            });
        },
        createBillingManual() {
            commonAct.showConfirm("Create billing manual?", () => {
                this.createBillingProcessing = true;
                this.createBillingModal.userId = this.ownedTarget;
                this.createBillingModal.isSendMail = false;
                this.createBillingModal.startTime = moment(this.createBillingModalTimeSelected[0]).format('DD/MM/YYYY HH:mm:ss');
                this.createBillingModal.endTime = moment(this.createBillingModalTimeSelected[1]).format('DD/MM/YYYY HH:mm:ss');
                vmMachineApi.createBillingManual(this.createBillingModal.machineId, this.createBillingModal).then(response => {
                    if (response.data && response.data.result === 0) {
                        this.hideLoading();
                        this.showSuccessToast("Request create billing for machine has been success");
                        this.closeCreateBilling();
                    }
                    else {
                        this.hideLoading();
                        if (response.data && response.data.message !== null && response.data.message !== '') {
                            commonAct.showError(response.data.message || this.$lang.common.error);
                        }
                    }
                    this.createBillingProcessing = false;
                }).catch(error => {
                    console.error(error);
                    this.createBillingProcessing = false;
                    this.hideLoading();
                    commonAct.showError(error);
                });
            });
        },
        onWaypoint({ element }) {
            if (!element.hasOwnProperty("isLazyloadData") || !element.isLazyloadData) {
                getlazyLoadInfo(this, element.userInfo, element.userId);
                this.$set(element, 'isLazyloadData', true);
                console.log("onWaypoint");
            }
        },

        openSupportTicketModal(targetItem) {
            let defaultCategory = mappingTicketType["BILLING"];
            this.$set(this.reportTicketModal, "targetItem", targetItem);
            this.$set(this.reportTicketModal, "userInfo", targetItem.userInfo);
            this.$set(this.reportTicketModal, "machineInfo", null);
            this.$set(this.reportTicketModal, "postModelData", {
                subject: `${targetItem.userInfo.username}`,
                mess: "",
                category_id: defaultCategory,
                type_id: null,
                machineId: null,
                username: `${targetItem.userInfo.username}`,
                name: this.userInfo.fullName,
                targetItemIdentity: targetItem.code
            }); 
            this.$nextTick(() => {
                this.$set(this.reportTicketModal, "isShowModal", true);
            });
        },
        closeSupportTicketModal() {
            this.$set(this.reportTicketModal, "isShowModal", false);
            this.$set(this.reportTicketModal, "userInfo", null);
            this.$set(this.reportTicketModal, "machineInfo", null);
            this.$set(this.reportTicketModal, "postModelData", null); 
        },
    }
}