import { gpuHubCnf } from '@/constant/config.js';
import axios from "axios";
const queryString = require('query-string');
export default {
    getElementsList(pageNumber, requestParam) {
        let queryParams = queryString.stringify(Object.assign({
            queryAction: requestParam.queryAction,
            querySearch: requestParam.querySearch,
            pageIndex: pageNumber,
            pageSize: requestParam.pageSize,
            machineId: requestParam.machineId,
        }, requestParam.filterItem));

        return axios({
            method: "GET",
            url: `${gpuHubCnf.getListBilling}/${requestParam.ownedTarget !== null ? requestParam.ownedTarget : ''}?${queryParams}`,
            withCredentials: true,
        });
    },
    getListBillingByMachine(machineId, pageNumber, requestParam) {
        let queryParams = queryString.stringify(Object.assign({
            queryAction: requestParam.queryAction,
            querySearch: requestParam.querySearch,
            pageIndex: pageNumber,
            pageSize: requestParam.pageSize,
        }, requestParam.filterItem));

        return axios({
            method: "GET",
            url: `${gpuHubCnf.getListBillingByMachine.format(machineId)}/${requestParam.ownedTarget !== null ? requestParam.ownedTarget : ''}?${queryParams}`,
            withCredentials: true,
        });
    }
};